<template>
  <div
    class="nav-item row no-gutters border-bottom py-4"
    :class="classes"
    @click="$emit('change-page', number)" >
    <div class="col px-2 font-weight-bold">
      <span class="nav-number rounded-circle px-2">{{ number }}</span>
      <span class="nav-text pl-4">{{ text }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    number: Number,
    text: String,
    active: Boolean,
  },
  computed: {
    classes() {
      return this.active ? ['active'] : [];
    },
  },
};
</script>

<style lang="scss">
</style>
