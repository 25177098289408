<template>
  <div class="div">
    <div class="form-row pt-1">
      <div class="col">
        <label class="font-weight-bold small-label" for="event-whats_included">Minutes</label>
        <input
          type="number"
          class="v-col form-control"
          id="duration-minutes"
          label="Minutes"
          min=-1
          max=60
          v-model.number="durationMinutes"
        />
      </div>

      <div class="col">
        <label class="font-weight-bold small-label" for="event-whats_included">Hours</label>
        <input
          type="number"
          class="v-col form-control"
          id="duration-hours"
          label="Hours"
          min=-1
          max=24
          v-model.number="durationHours"
        />
      </div>

      <div class="col">
        <label class="font-weight-bold small-label" for="event-whats_included">Days</label>
        <input
          type="number"
          class="v-col form-control"
          id="duration-days"
          label="Days"
          min=-1
          max=366
          v-model.number="durationDays"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    milliSeconds: Number,
  },

  created() {
    let momentMilliseconds = moment.duration(this.milliSeconds, 'milliseconds');
    let currentMs = this.milliSeconds;
    if (currentMs && momentMilliseconds.days() > 0) {
      this.durationDays = momentMilliseconds.days();
      currentMs -= moment.duration(this.durationDays, 'days').asMilliseconds();
      momentMilliseconds = moment.duration(currentMs, 'milliseconds');
    }

    if (currentMs && momentMilliseconds.hours() > 0) {
      this.durationHours = momentMilliseconds.hours();
      currentMs -= moment.duration(this.durationHours, 'hours').asMilliseconds();
      momentMilliseconds = moment.duration(currentMs, 'milliseconds');
    }

    if (currentMs && momentMilliseconds.minutes() > 0) {
      this.durationMinutes = momentMilliseconds.minutes();
    }
  },

  data() {
    return {
      durationMinutes: 0,
      durationHours: 0,
      durationDays: 0,
      durationMiliSeconds: 0,
    };
  },

  watch: {
    durationMinutes(val) {
      if (val < 0) {
        this.durationMinutes = 59;
      } else if (val > 59) {
        this.durationMinutes = 0;
      }
      this.getTotalMiliSeconds();
    },

    durationHours(val) {
      if (val < 0) {
        this.durationHours = 23;
      } else if (val > 23) {
        this.durationHours = 0;
      }
      this.getTotalMiliSeconds();
    },

    durationDays(val) {
      if (val < 0) {
        this.durationDays = 365;
      } else if (val > 365) {
        this.durationDays = 0;
      }
      this.getTotalMiliSeconds();
    },
  },

  methods: {
    getTotalMiliSeconds() {
      const momentMinutes = moment.duration(this.durationMinutes, 'minutes');
      const momentHours = moment.duration(this.durationHours, 'hours');
      const momentDays = moment.duration(this.durationDays, 'days');
      this.durationMiliSeconds = momentMinutes.asMilliseconds()
      + momentHours.asMilliseconds() + momentDays.asMilliseconds();
      console.log('current milliseconds are:: ', this.durationMiliSeconds);
      this.$emit('currentMilliSeconds', this.durationMiliSeconds);
      return this.durationMiliSeconds;
    },
  },

  computed: {
    relativeTime: {
      cache: false,
      set(value) {
        if (value) {
          this.durationMiliSeconds = value;
        } else {
          this.durationMiliSeconds = this.getTotalMiliSeconds();
        }
      },

      get() {
        const momentSeconds = moment.duration(this.durationMiliSeconds, 'milliseconds');
        console.log('Current Relative Time is:: ', momentSeconds.humanize());
        return `Approx: ${momentSeconds.humanize()}`;
      },
    },
  },
};
</script>

<style scoped>
.small-label {
  border-radius: 20px;
  font-size: 12px;
  font-weight: bold;
}
</style>
