<template>
  <!-- eslint-disable max-len -->
  <div class="col bg-light event-detail">
    <div class="row pt-4">
      <div class="col-lg-10 col-12 px-3 details-container">
        <!-- Page One -->
        <template v-if="currentPageNumber === 1">
          <form v-on:submit.prevent :class="formClasses">
            <div class="form-row d-flex align-items-center">
              <div class="col-lg-1 mb-2">
                <span
                  class="page-number px-3 py-2 border rounded bg-light w-100">
                  {{ currentPaddedPageNumber }}
                </span>
              </div>
              <div class="col-lg-10 col-md-12 d-flex mb-2 flex-column">
                <span class="page-title">
                  {{ currentPage.title }}
                </span>
                <span class="page-description">
                  {{ currentPage.description }}
                </span>
              </div>
              <template  v-if="this.eventId">
                <div class="col-1">
                  <button class="btn btn-sm d-flex align-items-center justify-content-center bg-light text-primary"
                    @click="actionDelete"
                  >
                    <span class="material-icons-outlined">
                      delete
                    </span>
                  </button>
                </div>
              </template>
            </div>
            <div class="form-row pt-4">
              <div class="col">
                <label class="font-weight-bold" for="event-title">Title</label>
                <input type="text" class="form-control" id="event-title" aria-describedby="validationFeedbackTitle"
                  v-model="title"
                  :class="{ 'is-invalid': getFieldError('title') }"
                />
                <div class="invalid-feedback" id="validationFeedbackTitle">
                  {{ getFieldError('title') }}
                </div>
              </div>
            </div>
            <div class="form-row pt-4">
              <div class="col">
                <label class="font-weight-bold" for="event-description">Description</label>
                <textarea rows="12" class="form-control" id="event-description" aria-describedby="validationFeedbackDescription"
                  v-model="description"
                  :class="{ 'is-invalid': getFieldError('description') }"
                />
                <div class="invalid-feedback" id="validationFeedbackDescription">
                  {{ getFieldError('description') }}
                </div>
              </div>
            </div>
            <template v-if="!isExperienceView">
              <div class="form-row pt-4">
                <div class="col">
                  <label class="font-weight-bold" for="date-input">Date and Time</label>
                  <input type="hidden" id="date-input" />
                </div>
              </div>
              <div class="form-row pt-2 pb-2">
                <div class="col">
                  <button class="btn btn-sm d-flex align-items-center"
                    @click="actionAddDate"
                  >
                    <span class="material-icons-outlined mr-2">
                    add
                    </span> Add date
                  </button>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="form-row pt-4">
                <div class="col">
                  <label class="font-weight-bold" for="duration-input">
                    Duration of the Experience:
                    <span class="font-weigh-light small pl-2" v-if="humanReadableDuration">{{ humanReadableDuration }}</span>
                  </label>
                  <input type="hidden" id="duration-input" />
                </div>
              </div>
            </template>
            <template v-if="!isExperienceView">
              <template v-for="(date, index) in dates">
                <div :key="date.uuid" class="form-row">
                  <div class="col-lg-10 col-md-12 border rounded mt-3 px-4 py-2">
                    <div class="form-row">
                      <!-- Date picker -->
                      <div class="col">
                        <event-date-picker
                          :index="`${date.uuid}${index}1`"
                          :date="date.start_time"
                          :label="'Event Starts'"
                          :mode="'date'"
                          :iconName="'calendar_today'"
                          v-on:date-change="date.start_time = $event"
                        />
                        <small class="text-danger ml-2">
                          {{ date.start_time_error }}
                        </small>
                      </div>

                      <!-- Time picker -->
                      <div class="col">
                        <event-date-picker
                          :index="`${date.uuid}${index}2`"
                          :date="date.start_time"
                          :label="'Start Time'"
                          :mode="'time'"
                          v-on:date-change="date.start_time = $event"
                        />
                      </div>
                    </div>

                    <div class="form-row pt-2">
                      <!-- Date picker -->
                      <div class="col">
                        <event-date-picker
                          :index="`${date.uuid}${index}3`"
                          :date="date.end_time"
                          :label="'Event Ends'"
                          :mode="'date'"
                          :iconName="'calendar_today'"
                          v-on:date-change="date.end_time = $event"
                        />
                        <small class="text-danger ml-2">
                          {{ date.end_time_error }}
                        </small>
                      </div>

                      <!-- Time picker -->
                      <div class="col">
                        <event-date-picker
                          :index="`${date.uuid}${index}4`"
                          :date="date.end_time"
                          :label="'End Time'"
                          :mode="'time'"
                          v-on:date-change="date.end_time = $event"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-2 mt-1 d-flex align-items-center justify-content-center">
                    <button class="btn btn-sm bg-light text-dark text-primary-on-hover"
                      @click="actionRemoveDate(date.uuid, date.new)"
                    >
                      <span class="material-icons-outlined">
                      delete
                      </span>
                    </button>
                  </div>
                </div>
              </template>
            </template>
            <template v-if="isExperienceView">
              <duration-picker v-if="loaded"
                :milliSeconds=event.duration
                @currentMilliSeconds="getEventDuration"
              />
              <div class="form-row pt-4">
                <div class="col">
                  <label class="font-weight-bold" for="event-whats_included">What's Included</label>
                  <textarea rows="6" class="form-control" id="event-whats_included" aria-describedby="validationFeedbackWhatsIncluded"
                    v-model="whats_included"
                    :class="{ 'is-invalid': getFieldError('whats_included') }"
                  />
                  <div class="invalid-feedback" id="validationFeedbackWhatsIncluded">
                    {{ getFieldError('whats_included') }}
                  </div>
                </div>
              </div>

              <div class="form-row pt-4">
                <div class="col">
                  <label class="font-weight-bold" for="event-cancel-policy">Cancellation Policy</label>
                  <textarea rows="6" class="form-control" id="event-cancel-policy" aria-describedby="validationFeedbackCancelPolicy"
                    v-model="cancelPolicy"
                    :class="{ 'is-invalid': getFieldError('cancel_policy') }"
                  />
                  <div class="invalid-feedback" id="validationFeedbackCancelPolicy">
                    {{ getFieldError('cancel_policy') }}
                  </div>
                </div>
              </div>

              <div class="form-row pt-4">
                <div class="col">
                  <label class="font-weight-bold" for="event-essentials">What to Bring</label>
                  <textarea rows="6" class="form-control" id="event-essentials" aria-describedby="validationFeedbackEssentials"
                    v-model="essentials"
                    :class="{ 'is-invalid': getFieldError('essentials') }"
                  />
                  <div class="invalid-feedback" id="validationFeedbackEssentials">
                    {{ getFieldError('essentials') }}
                  </div>
                </div>
              </div>
            </template>
          </form>
        </template>
        <!-- End Page One -->

        <!-- Page Two -->
        <template v-else-if="currentPageNumber === 2">
          <form v-on:submit.prevent :class="formClasses">
            <div class="form-row d-flex align-items-center">
              <div class="col-lg-1 mb-2">
                <span
                  class="page-number px-3 py-2 border rounded bg-light w-100">
                  {{ currentPaddedPageNumber }}
                </span>
              </div>
              <div class="col-lg-10 col-md-12 d-flex flex-column">
                <span class="page-title">
                  {{ currentPage.title }}
                </span>
                <template v-if="currentPage.description">
                  <span class="page-description">
                    {{ currentPage.description }}
                  </span>
                </template>
              </div>
            </div>

            <div class="form-row pt-4">
              <div class="col">
                <label class="font-weight-bold" for="tagSelection">Select Category</label>
                <small id="categoryHelpBlock" class="form-text text-muted pb-3">
                  Add relevant tags to your event
                </small>

                <multiselect
                  placeholder="Pick some" label="name" track-by="uuid"
                  v-model="selectedTags"
                  :options="tagOptions"
                  :searchable="true"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="true"
                  :preselect-first="true"
                >
              </multiselect>
              </div>
            </div>

            <div class="form-row pt-4">
              <div class="col">
                <label class="font-weight-bold" for="venueSelection">Venue</label>
                <small id="venueHelpBlock" class="form-text text-muted pb-3">
                  Help people to know where your event is
                </small>

                <multiselect
                  placeholder="Pick a venue" label="name" track-by="uuid"
                  v-model="selectedVenue"
                  :options="venueOptions"
                  :searchable="true"
                  :multiple="false"
                  :close-on-select="true"
                  :clear-on-select="true"
                  :preselect-first="false"
                >
              </multiselect>
              <div class="text-danger custom-invalid-feedback" v-if="getFieldError('venue')" id="validationFeedbackVenue">
                  {{ getFieldError('venue') }}
              </div>
              </div>
            </div>

            <div class="form-row pt-3">
              <div class="col-lg-2 col-md-5">
                <button class="btn d-flex align-items-center"
                  :class="[showNewVenueFields ? 'bg-danger': 'bg-primary']"
                  @click="showNewVenueFields=!showNewVenueFields"
                >
                  <span class="material-icons-outlined mr-1 font-14">
                    {{ showNewVenueFields ? 'close' : 'add' }}
                  </span>
                  <span class="font-14">
                    {{ showNewVenueFields ? 'Close' : 'Add New' }}
                  </span>
                </button>
              </div>
            </div>

            <template v-if="showNewVenueFields">
              <div class="form-row mt-2 mx-0 py-4 px-3 border rounded">
                <div class="col">
                  <div class="row">
                    <div class="col">
                      <GmapMap
                        :center="mapsCenter"
                        :zoom="7"
                        map-type-id="roadmap"
                        style="width: 100%; height: 45vh"
                      />
                    </div>
                  </div>
                  <div class="row pt-4">
                    <div class="col-lg-4 col-md-6">
                      <custom-text-input
                        :id="`new-venue-label`"
                        :label="'Venue Name'"
                        :default="newVenue.name"
                        :required="true"
                        v-on:input="$set(newVenue, 'name', $event)"
                      />
                    </div>
                  </div>
                  <div class="row pt-4">
                    <div class="col">
                      <custom-text-input
                        :id="`new-venue-description`"
                        :label="'Description'"
                        :default="newVenue.description"
                        :required="false"
                        :rows="3"
                        v-on:input="$set(newVenue, 'description', $event)"
                      />
                    </div>
                  </div>
                  <div class="row pt-4">
                    <div class="col-lg-2 col-md-3 col-sm-5 mb-2">
                      <button class="btn bg-primary">
                        Save Venue
                      </button>
                    </div>
                    <div class="col-lg-2 col-md-3 col-sm-5">
                      <button class="btn bg-danger"
                        @click="showNewVenueFields=false"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="form-row pt-4">
                <div class="col">
                  <div class="row">
                    <div class="col-12">
                      <label class="font-weight-bold" for="mainImageSelection">
                        Main Event Image
                      </label>
                      <small id="venueHelpBlock" class="form-text text-muted pb-3">
                        This is the main image of your event. <br/>
                        We recommend using at least a 2160*1080 (2:1 ratio) image.
                      </small>
                    </div>
                  </div>

                  <div class="row pr-1">
                    <div class="col-9">
                      <template v-if="mainEventImageSrc">
                        <div class="img-container text-left">
                          <img class="img-fluid" :src="mainEventImageSrc" />
                          <div class="btn-action d-flex align-items-center">
                            <button class="btn btn-sm delete-btn"
                              @click="removeMainEventImage"
                            >
                              <span class="material-icons-outlined">
                              delete
                              </span>
                            </button>
                            <button class="btn btn-sm edit-btn ml-1"
                              @click="onImageEditClick({ event: $event, field: 'event_image'})"
                              data-toggle="modal" data-target="#editImageModal"
                            >
                              <span class="material-icons-outlined">
                              edit
                              </span>
                            </button>
                          </div>
                        </div>
                      </template>
                      <template v-else>
                        <file-drag-n-drop-input
                          :id="'mainImageFileInput'"
                          :text="'Drag & drop or click to add main event image.'"
                          :data="{ field: 'event_image', index: null }"
                          v-on:drop="onImageDrop"
                          v-on:clicked="onImageEditClick"
                          data-toggle="modal" data-target="#editImageModal"
                        >
                          <template v-slot:file>
                            <span id="mainImageFileInput" />
                          </template>
                        </file-drag-n-drop-input>
                      </template>
                        <div class="text-danger custom-invalid-feedback" v-if="getFieldError('event_image')" id="validationFeedbackEventImage">
                          {{ getFieldError('event_image') }}
                      </div>
                    </div>

                    <!-- Modal -->
                    <div class="modal fade" ref="imagemodal" id="editImageModal" tabindex="-1"
                      aria-labelledby="imageModalLabel" aria-hidden="true"
                    >
                      <div
                        class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h5 class="modal-title" id="imageModalLabel">
                              <template v-if="imageListBrowse">
                                Browse Images
                              </template>
                              <template v-else>
                                Upload Image
                              </template>
                            </h5>
                            <button type="button" class="close"
                              data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div class="modal-body">
                            <div class="row">
                              <div class="col">
                                <div class="btn-switch">
                                  <button class="btn"
                                    :disabled="imageListBrowse"
                                    @click="modalBrowseImages">
                                    Browse
                                  </button>
                                  <button class="btn ml-2"
                                    :disabled="!imageListBrowse"
                                    @click="imageListBrowse = false">
                                    Upload
                                  </button>
                                </div>
                              </div>
                            </div>
                            <template v-if="imageListBrowse">
                              <div class="row py-2">
                                <div class="col-4" style="overflow-y: scroll; max-height: 60vh;">
                                  <template v-for="image in imageList">
                                    <div class="row py-1"
                                      style="height: 10rem;"
                                      :key="image.uuid"
                                    >
                                      <div
                                        class="col text-center">
                                        <img
                                          class="img-list-item img-fluid"
                                          :src="image.image"
                                          :alt="image.alt"
                                          :class="{
                                            'active': imageListCurrentImage.uuid === image.uuid
                                            }"
                                          @click="imageListCurrentImage = image" />
                                      </div>
                                    </div>
                                  </template>
                                </div>
                                <div class="col-8 d-flex align-items-center justify-content-center"
                                  style="max-height: 60vh;">
                                  <img :src="imageListCurrentImageSrc" class=""
                                    style="max-height: 100%; max-width: 100%;" />
                                </div>
                              </div>
                            </template>
                            <template v-else>
                              <div
                                class="row py-2 d-flex align-items-center justify-content-center">
                                <template v-if="isimageModalFileUploading">
                                  <div class="col py-4">
                                    <div class="spinner-border" role="status">
                                      <span class="sr-only">Loading...</span>
                                    </div>
                                  </div>
                                </template>
                                <template v-else>
                                  <div class="col-9">
                                    <file-drag-n-drop-input
                                      :id="'imageListFileUpload'"
                                      :data="activeImageFieldData"
                                      v-on:drop="onImageDrop"
                                      v-on:file-upload="onModalFileDialogSelect"
                                    />
                                  </div>
                                </template>
                              </div>
                            </template>
                          </div>
                          <div v-if="imageListBrowse" class="modal-footer">
                            <button type="button" class="btn btn-secondary"
                              @click="buttonCancelModalSelection">
                              Cancel
                            </button>
                            <button type="button" class="btn btn-primary"
                              @click="buttonSelectModalImage"
                            >
                              Choose image
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

              <div class="form-row pt-4">
                <div class="col">
                  <div class="row">
                    <div class="col-12 mt-2">
                      <label class="font-weight-bold" for="otherImageSelection">
                        Other Images
                      </label>
                      <small id="otherImageSelection" class="form-text text-muted pb-3">
                        You can add upto 3 more images of your event. <br />
                        We recommend using at least a 2160*1080 (2:1 ratio image). JPEG or PNG
                      </small>
                    </div>
                  </div>

                  <div class="row pr-1">
                    <template v-for="(image, index) in images">
                      <div class="col-lg"
                        :key="index"
                      >
                        <template v-if="getImageSrc(image)">
                          <div class="img-container text-left">
                            <img class="img-fluid" :src="getImageSrc(image)" />
                            <div class="btn-action d-flex align-items-center">
                              <button class="btn btn-sm delete-btn"
                                @click="removeImagesItem(index)"
                              >
                                <span class="material-icons-outlined">
                                delete
                                </span>
                              </button>
                              <button class="btn btn-sm edit-btn ml-1"
                                @click="onImageEditClick({ event: $event, field: 'images', index})"
                                data-toggle="modal" data-target="#editImageModal"
                              >
                                <span class="material-icons-outlined">
                                edit
                                </span>
                              </button>
                            </div>
                          </div>
                        </template>
                        <template v-else>
                          <file-drag-n-drop-input
                            :id="`imagesItem${index}`"
                            :text="null"
                            :helpText="null"
                            :data="{ field: 'images', index }"
                            :icon="'add_photo_alternate'"
                            v-on:drop="onImageDrop"
                            v-on:clicked="onImageEditClick"
                            data-toggle="modal" data-target="#editImageModal"
                          >
                            <template v-slot:file>
                              <span id="imagesItemFileInput" />
                            </template>
                          </file-drag-n-drop-input>
                        </template>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </template>
          </form>
        </template>
        <!-- End Page Two -->

        <!-- Page Three -->
        <template v-else-if="currentPageNumber === 3">
          <form v-on:submit.prevent :class="formClasses">
            <div class="form-row d-flex align-items-center">
              <div class="col-lg-1 mb-2">
                <span
                  class="page-number px-3 py-2 border rounded bg-light w-100">
                  {{ currentPaddedPageNumber }}
                </span>
              </div>
              <div class="col-lg-10 col-md-12 d-flex flex-column">
                <span class="page-title">
                  {{ currentPage.title }}
                </span>
                <template v-if="currentPage.description">
                  <span class="page-description">
                    {{ currentPage.description }}
                  </span>
                </template>
              </div>
            </div>

            <template v-for="(ticket, index) in activeTickets">
              <div class="form-row event-tickets mt-4 py-4 border rounded px-3 ml-1" :key="ticket.uuid">
                <div class="col">
                  <div class="row pt-3 mb-3">
                    <div class="col-lg-6 col-md-12">
                      <custom-text-input
                        :id="`ticket-label-${index}`"
                        :label="'Name'"
                        :default="ticket.label"
                        :required="true"
                        :helpText="'Ticket name EX: Early birder, General, VIP'"
                        :error="ticket.label_error"
                        v-on:input="ticket.label=$event"
                      />
                    </div>
                    <div class="col-lg-5" />
                    <div class="col-lg-1 text-center">
                      <button class="btn btn-sm bg-light text-primary border d-flex align-items-center"
                        @click="actionDeleteTicket(ticket)"
                      >
                        <span class="material-icons-outlined">
                        delete
                        </span>
                      </button>
                    </div>
                  </div>

                  <div class="row pt-3 mb-3">
                    <div class="col">
                      <custom-text-input
                        :id="`ticket-description-${index}`"
                        :label="'Description'"
                        :default="ticket.description"
                        :rows="12"
                        :helpText="'Ticket description'"
                        :error="ticket.description_error"
                        v-on:input="ticket.description=$event"
                      />
                    </div>
                  </div>

                  <!-- <div class="row pt-2 mb-3">
                    <div class="col-lg mb-3">
                      <label class="font-weight-bold" for="ticketDateSelection">Date</label>

                      <multiselect
                        placeholder="Pick a date" label="name" track-by="uuid"
                        v-model="ticket.event_date"
                        :options="dateOptions"
                        :searchable="false"
                        :multiple="false"
                        :close-on-select="true"
                        :clear-on-select="true"
                        :preselect-first="false"
                      >
                      </multiselect>
                      <template v-if="ticket.event_date_error">
                        <div class="text-danger ml-2 custom-invalid-feedback" id="validationFeedbackTicketDate">
                          {{ ticket.event_date_error }}
                        </div>
                      </template>
                      <template v-else>
                        <small class="text-secondary ml-2">
                          Only saved dates will appear here
                        </small>
                      </template>
                    </div>
                  </div> -->

                  <div class="row pt-2">
                    <div class="col-lg">
                      <custom-text-input
                        :id="`ticket-qty-available-${index}`"
                        :label="'Available Quantity'"
                        :default="ticket.qty_available"
                        :required="true"
                        :error="ticket.qty_available_error"
                        v-on:input="ticket.qty_available=$event"
                      />
                    </div>

                    <div class="col-lg d-flex align-items-center mb-3">
                      <div class="custom-control custom-switch d-flex align-items-center">
                        <input type="checkbox" class="custom-control-input" :id="`ticketFreeSwitch-${index}`"
                          v-model="ticket.free"
                          @change="setTicketFree(ticket, $event.target.checked)"
                        >
                        <label class="custom-control-label" :for="`ticketFreeSwitch-${index}`">
                          Toggle to mark as {{ ticket.free ? 'paid' : 'free' }}
                        </label>
                      </div>
                    </div>
                    <div class="col-lg mb-3" v-if="!ticket.free">
                      <label class="font-weight-bold" for="ticketCurrencySelection">
                        Currency
                      </label>

                      <multiselect
                        placeholder="Pick a value"
                        v-model="ticket.price_currency"
                        :options="['SEK']"
                        :searchable="true"
                        :multiple="false"
                        :close-on-select="true"
                        :clear-on-select="true"
                        :preselect-first="true"
                        :disabled="true"
                      >
                      </multiselect>
                      <div class="text-danger custom-invalid-feedback" v-if="getFieldError('ticket-currency')" id="validationFeedbackTicketCurrency">
                          {{ ticket.price_currency_error }}
                      </div>
                    </div>
                    <div class="col-lg" v-if="!ticket.free">
                      <custom-text-input
                        :id="`ticket-price-${index}`"
                        :label="'Price'"
                        :default="ticket.price"
                        :required="true"
                        :error="ticket.price_error"
                        v-on:input="ticket.price=$event"
                      />
                    </div>
                  </div>

                  <div class="row pt-2">
                    <div class="col-6">
                      <event-date-picker
                        :index="`start-date-${index}`"
                        :date="ticket.start_time"
                        :label="'Sales start'"
                        :mode="'date'"
                        :iconName="'calendar_today'"
                        :error="ticket.start_time_error"
                        v-on:date-change="ticket.start_time = $event"
                      />
                    </div>

                    <div class="col-6">
                      <event-date-picker
                        :index="`start-time-${index}`"
                        :date="ticket.start_time"
                        :label="'Start Time'"
                        :mode="'time'"
                        :error="ticket.start_time_error && ' '"
                        v-on:date-change="ticket.start_time = $event"
                      />
                    </div>
                  </div>

                  <div class="row pt-2">
                    <div class="col-6">
                      <event-date-picker
                        :index="`end-date-${index}`"
                        :date="ticket.end_time"
                        :label="'Sales end'"
                        :mode="'date'"
                        :iconName="'calendar_today'"
                        :error="ticket.end_time_error"
                        v-on:date-change="ticket.end_time = $event"
                      />
                    </div>

                    <div class="col-6">
                      <event-date-picker
                        :index="`end-time-${index}`"
                        :date="ticket.end_time"
                        :label="'End Time'"
                        :mode="'time'"
                        :error="ticket.end_time_error && ' '"
                        v-on:date-change="ticket.end_time = $event"
                      />
                    </div>
                  </div>

                  <div class="row pt-2">
                    <div class="col">
                      <span class="font-weight-bold">
                        Tickets per order
                      </span>
                    </div>
                  </div>

                  <div class="row pt-2 mb-3">
                    <div class="col">
                      <custom-text-input
                        :id="`ticket-min-order-qty-${index}`"
                        :label="'Minimum Quantity'"
                        :default="ticket.qty_order_min"
                        :required="true"
                        :error="ticket.qty_order_min_error"
                        v-on:input="ticket.qty_order_min=$event"
                      />
                    </div>
                    <div class="col">
                      <custom-text-input
                        :id="`ticket-max-order-qty-${index}`"
                        :label="'Maximum Quantity'"
                        :default="ticket.qty_order_max"
                        :required="true"
                        :error="ticket.qty_order_max_error"
                        v-on:input="ticket.qty_order_max=$event"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </template>

            <div class="form-row mt-2 py-4">
              <div class="col text-center">
                <button class="btn btn-lg"
                  @click="actionAddTicket"
                >
                  Add a ticket
                </button>
              </div>
            </div>
          </form>
        </template>
        <!-- End Page Three -->

        <!-- Page Four -->
        <template v-else-if="currentPageNumber === 4">
          <div class="row">
            <div class="col text-center">
              <span class="font-weight-bold font-24">
                {{ event.title }}
              </span>
            </div>
          </div>
          <div class="row pt-2">
            <div class="col">
              <div id="previewCarousel" class="carousel slide" data-ride="carousel">
                <div class="carousel-inner">
                  <template v-for="(image, index) in previewCarouselImages">
                    <div class="carousel-item"
                      :key="image.uuid"
                      :class="{'active': index === 0}"
                    >
                      <img class="d-block w-100"
                        :src="image.image"
                        :alt="image.alt"
                        style="object-fit: contain; max-height: 50vh;"
                      >
                    </div>
                  </template>
                </div>
                <a class="carousel-control-prev" href="#previewCarousel" role="button" data-slide="prev">
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#previewCarousel" role="button" data-slide="next">
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="sr-only">Next</span>
                </a>
              </div>
            </div>
          </div>
          <div class="row px-3 pt-3">
            <div class="col">
              <div class="row">
                <div class="col-4 d-flex align-items-center font-weight-bold">
                  <span class="material-icons-outlined">
                  event
                  </span>
                  <span class="ml-2">{{ parseEventDate(startDate) }}</span>
                </div>
                <div class="col-3 d-flex align-items-center font-weight-bold">
                  <span class="material-icons-outlined">
                  location_on
                  </span>
                  <span class="ml-2">{{ event.venue.name }}</span>
                </div>
              </div>
              <div class="row pt-4">
                <div class="col">
                  <p class="text-secondary">{{ description }}</p>
                </div>
              </div>

              <template v-if="isExperienceView">
                <div class="row pt-4"
                  v-if="event.whats_included"
                >
                  <div class="col">
                    <div class="row">
                      <div class="col border-top font-24 font-weight-bold text-center pt-2">
                        What's Included
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <p class="text-secondary">
                          {{ event.whats_included }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row pt-4"
                  v-if="event.essentials"
                >
                  <div class="col">
                    <div class="row">
                      <div class="col border-top font-24 font-weight-bold text-center pt-2">
                        What to Bring
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <p class="text-secondary">
                          {{ event.essentials }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row pt-4"
                  v-if="event.cancel_policy"
                >
                  <div class="col">
                    <div class="row">
                      <div class="col border-top font-24 font-weight-bold text-center pt-2">
                        Cancellation Policy
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <p class="text-secondary">
                          {{ event.cancel_policy }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </template>

              <template v-if="lineups && lineups.length > 0">
                <div class="row pt-4">
                  <div class="col">
                    <div class="row">
                      <div class="col border-top pt-2 text-center font-weight-bold font-24">
                        Line up
                      </div>
                    </div>
                    <div class="row">
                      <template v-for="lineup in lineups">
                        <div class="col"
                          :key="lineup.uuid"
                        >
                          Line up
                        </div>
                      </template>
                    </div>
                    <div class="row pb-4">
                      <div class="col text-center">
                        <button class="btn btn-sm">
                          See all artists
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </template>

              <template v-if="tickets && tickets.length > 0">
                <div class="row">
                  <div class="col">
                    <div class="row">
                      <div class="col border-top font-weight-bold font-24 text-center pb-3 pt-3">
                        Tickets
                      </div>
                    </div>

                    <template v-for="ticket in tickets">
                      <div class="row px-3"
                        :key="ticket.uuid"
                      >
                        <div class="col border rounded px-4">
                          <div class="row pt-4 mx-1"
                            :class="[ ticket.description ? 'pb-2' : 'pb-4']"
                          >
                            <div class="col font-weight-bold">
                              {{ ticket.label }}
                            </div>
                            <div class="col">
                              <span class="font-weight-bold">
                                {{ ticket.price }} {{ ticket.price_currency }}
                              </span> incl fees
                            </div>
                          </div>
                          <div
                            v-if="ticket.description"
                            class="row pb-2 border-top mx-2"
                          >
                            <div class="col pt-2 px-0">
                              <template v-if="ticket.show_description">
                                <p class="px-3 pt-2 text-secondary">
                                  {{ ticket.description }}
                                </p>
                                <p>
                                  <a
                                    @click="$set(ticket, 'show_description', false)"
                                    class="d-flex align-items-center font-12 text-secondary pointer-on-hover"
                                    style="text-decoration: none !important;"
                                  >
                                    <span class="material-icons-outlined">
                                    expand_less
                                    </span> Hide details
                                  </a>
                                </p>
                              </template>
                              <template v-else>
                                <a
                                  @click="$set(ticket, 'show_description', true)"
                                  class="d-flex align-items-center font-12 text-secondary pointer-on-hover"
                                  style="text-decoration: none !important;"
                                >
                                  <span class="material-icons-outlined">
                                  expand_more
                                  </span> More details
                                </a>
                              </template>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
              </template>

              <div class="row pt-2">
                <div class="col">
                  <div class="row">
                    <div class="col font-weight-bold text-center font-24">
                      Location
                    </div>
                  </div>
                  <div class="row pt-2">
                    <div class="col ml-4">
                      <span class="font-weight-bold">
                        Venue:
                      </span> {{ event.venue.name }}
                    </div>
                    <div class="col">
                      <span class="font-weight-bold">
                        Address:
                      </span> {{ event.venue.location }}
                    </div>
                  </div>
                  <div class="row pt-2">
                    <div class="col">
                      <GmapMap
                        :center="getVenuePosition()"
                        :zoom="13"
                        map-type-id="roadmap"
                        style="width: 100%; height: 45vh"
                      >
                        <GmapMarker
                          :position="getVenuePosition()"
                          :clickable="false"
                          :draggable="true"
                        />
                      </GmapMap>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <!-- End Page Four -->

        <!-- Page Navigation -->
        <div class="row pt-4 pb-4">
          <div class="col d-flex justify-content-center">
            <button class="next-page btn btn-lg d-flex align-items-center"
              @click="buttonSaveChanges"
            >
              <span class="material-icons-outlined mr-1">
              save
              </span>
              Save Changes
            </button>
          </div>
          <div class="col d-flex justify-content-center">
            <button class="next-page btn btn-lg d-flex align-items-center"
              @click="changePage(nextPageNumber)"
            >
              {{ nextPageText }}
              <span class="material-icons-outlined">
              navigate_next
              </span>
            </button>
          </div>
        </div>
        <!-- End Page navigation -->
      </div>

      <div class="col-lg-2 bg-white event-detail-nav">
        <template v-for="(page, index) in pages">
          <event-detail-nav-item
            :key="index"
            :number="index + 1"
            :text="page.title"
            :active="currentPageNumber === index+1"
            v-on:change-page="changePage(index + 1)" />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Multiselect from 'vue-multiselect';
import * as $ from 'jquery';
import { DateTime } from 'luxon';
import _ from 'lodash';
import humanizeDuration from 'humanize-duration';

import EventDetailNavItem from '@/components/EventDetailNavItem.vue';
import EventDatePicker from '@/components/EventDatePicker.vue';
import FileDragNDropInput from '@/components/FileDragNDropInput.vue';
import CustomTextInput from '@/components/CustomTextInput.vue';
import DurationPicker from '@/components/DurationPicker.vue';

import notify from '@/notifications';
import Utils from '@/utils';

export default {
  components: {
    EventDetailNavItem,
    EventDatePicker,
    Multiselect,
    FileDragNDropInput,
    CustomTextInput,
    DurationPicker,
  },

  data() {
    return {
      formClasses: ['bg-white', 'px-4', 'pt-4', 'pb-2'],
      event: {
        uuid: '',
        title: '',
        images: [],
        venue: {},
        giveaways: [],
        event_image: {},
        description: '',
        tags: [],
        pinned: false,
        highlights: [],
        requirements: [],
        cancel_policy: '',
        ticket_data: {
          qty_balance: 20,
          qty_available: 22,
          price_currency: 'SEK',
          price_start: '200.0000',
          price_end: '210.9000',
          is_free: false,
        },
        dates: [],
        duration: null,
      },
      errors: {},
      imageList: [],
      imageListCurrentImage: '',
      imageListBrowse: false,
      selectedModalImage: {},
      activeImageFieldData: {},
      modalId: 'editImageModal',
      selectDatePosition: null,
      tickets: [],
      showNewVenueFields: false,
      newVenue: {
        name: '',
        description: '',
        latitude: 0,
        longitude: 0,
      },
      mapsCenter: { lat: 45.508, lng: -73.587 },
      lineups: [],
      loaded: false,
      humanReadableDuration: null,
    };
  },

  beforeRouteEnter(to, from, next) {
    console.debug('route enter');
    next((vm) => {
      const { eventId } = vm.$route.params;
      vm.updateData(eventId, next);
    });
  },

  beforeRouteUpdate(to, from, next) {
    const { page } = to.query;
    console.debug(`route update ${page}`);
    if (page) {
      const { eventId } = to.params;
      const fromEventId = (
        from.params
        && 'eventId' in from.params
      ) ? from.params.eventId.toString() : '';
      if (
        (fromEventId === eventId && this.loaded)
        || (eventId === '0')
      ) {
        next();
      } else {
        this.updateData(eventId, next);
      }
      console.debug('update data');
    } else {
      console.debug('next');
      next();
    }
  },

  computed: {
    ...mapState('tags', {
      tagOptions: 'items',
    }),
    ...mapState('venues', {
      venueOptions: 'items',
    }),

    pages() {
      const basicInfoPage = {
        title: 'Basic Info',
        description: 'Name your event, add the description and the event dates',
        nextPageText: 'Add Details',
      };
      const detailsPage = {
        title: 'Details',
        description: '',
        nextPageText: this.isExperienceView ? 'Preview' : 'Add Tickets',
      };
      const ticketsPage = {
        title: 'Tickets',
        description: '',
        nextPageText: 'Preview Event',
      };
      const publishPage = {
        title: 'Publish',
        description: '',
        nextPageText: 'Finish',
      };

      // if (this.isExperienceView) {
      //   return [
      //     basicInfoPage,
      //     detailsPage,
      //     publishPage,
      //   ];
      // }

      return [
        basicInfoPage,
        detailsPage,
        ticketsPage,
        publishPage,
      ];
    },

    isExperienceView() {
      return this.$route.meta.event_type === 'experience';
    },

    getRandomArbitrary() {
      return Math.random() * (100000 - 1) + 1;
    },

    currentPageNumber() {
      const currentPage = this.$route.query.page ? Number(this.$route.query.page) : 1;
      if (currentPage) {
        return currentPage;
      }
      return 1;
    },

    currentPaddedPageNumber() {
      return (`0${this.currentPageNumber}`).slice(-2);
    },

    currentPage() {
      return this.pages[this.currentPageNumber - 1];
    },

    nextPageNumber() {
      const nextPage = this.currentPageNumber + 1;
      return nextPage > this.pages.length ? null : nextPage;
    },

    nextPageText() {
      return this.currentPage ? this.currentPage.nextPageText : '';
    },

    eventId() {
      const { eventId } = this.$route.params;
      return this.isValidEventId(eventId) ? eventId : 0;
    },

    title: {
      set(value) {
        this.$set(this.event, 'title', value);
      },

      get() {
        return this.event.title || '';
      },
    },

    description: {
      set(value) {
        this.$set(this.event, 'description', value);
      },

      get() {
        return this.event.description || '';
      },
    },

    selectedTags: {
      set(value) {
        this.$set(this.event, 'tags', value);
      },

      get() {
        return this.event.tags ? this.event.tags : [];
      },
    },

    selectedVenue: {
      set(value) {
        this.$set(this.event, 'venue', value);
      },

      get() {
        return this.event.venue ? this.event.venue : {};
      },
    },

    cancelPolicy: {
      set(value) {
        this.$set(this.event, 'cancel_policy', value);
      },

      get() {
        return this.event.cancel_policy || '';
      },
    },

    essentials: {
      set(value) {
        this.$set(this.event, 'essentials', value);
      },

      get() {
        return this.event.essentials || '';
      },
    },

    whats_included: {
      set(value) {
        this.$set(this.event, 'whats_included', value);
      },

      get() {
        return this.event.whats_included || '';
      },
    },

    mainEventImage: {
      set(value) {
        this.$set(this.event, 'event_image', value);
      },

      get() {
        return this.event.event_image ? this.event.event_image : {};
      },
    },

    mainEventImageSrc() {
      return this.mainEventImage ? this.mainEventImage.image : '';
    },

    imageListCurrentImageSrc() {
      return this.imageListCurrentImage ? this.imageListCurrentImage.image : '';
    },

    images: {
      set(value) {
        this.$set(this.event, 'images', value);
      },

      get() {
        let { images } = this.event;
        if (!images) {
          images = [];
        }
        while (images.length < 3) {
          images.push({
            new: true,
            uuid: null,
            image: '',
            alt: '',
          });
        }
        return images;
      },
    },

    dates: {
      set(value) {
        this.$set(this.event, 'dates', value);
      },

      get() {
        return this.event.dates ? this.event.dates : [];
      },
    },

    startDate() {
      let { dates } = this.event;
      if (!dates) {
        dates = [];
      }
      if (dates.length < 1) {
        return 'Unknown';
      }
      return dates[0].start_time || 'Unknown';
    },

    hasDates() {
      return this.dates && this.dates.length > 0;
    },

    isimageModalFileUploading() {
      return this.$store.getters['images/isLoading']('imageModalFileUpload');
    },

    activeTickets() {
      const { tickets } = this;
      tickets.forEach((ticket) => {
        // eslint-disable-next-line no-param-reassign
        ticket.free = !Number(ticket.price);
        if (ticket.event_date && !_.isEmpty(ticket.event_date)) {
          // eslint-disable-next-line no-param-reassign
          ticket.event_date.name = this.getDateName(
            ticket.event_date.start_time,
            ticket.event_date.end_time,
          );
        }
      });
      return tickets;
    },

    dateOptions() {
      const dates = [];
      this.dates.forEach((date) => {
        if (!date.new) {
          dates.push({
            ...date,
            name: this.getDateName(
              date.start_time,
              date.end_time,
            ),
          });
        }
      });
      return dates;
    },

    previewCarouselImages() {
      const images = [];
      if (this.event.event_image) {
        images.push(this.event.event_image);
      }
      if (this.event.images && this.event.images.length > 0) {
        images.push(...this.event.images.filter((i) => i.image));
      }

      return images;
    },
  },

  methods: {
    getItem(itemId) {
      return this.$store.dispatch('events/getEvent', itemId);
    },

    updateData(eventId, next) {
      console.debug(`updating data for ${eventId}`);
      if (this.isValidEventId(eventId)) {
        console.debug('getting event data');
        this.getItem(eventId)
          .then((response) => {
            if (response.success) {
              this.event = response.data;
              this.loaded = true;
              this.getTickets(eventId)
                .then((ticketsResponse) => {
                  if (ticketsResponse.success) {
                    this.tickets = ticketsResponse.data.objects;
                  }
                });
              next();
            } else {
              console.debug('there was an error fetching the record');
              next(false);
            }
          });
      } else {
        this.loaded = true;
        this.event = {};
        next();
      }
      this.$store.dispatch('tags/getTags');
      this.$store.dispatch('venues/getVenues');
    },

    getTickets(eventId) {
      let request = null;
      if (this.isValidEventId(eventId)) {
        request = this.$store.dispatch(
          'tickets/getEventTickets',
          { eventId },
        );
      }
      return request;
    },

    isValidEventId(eventId) {
      return eventId && eventId.toString().trim() && eventId.toString() !== '0';
    },

    changePage(pageNumber) {
      console.debug(`switch to page ${pageNumber}`);
      if (pageNumber !== this.currentPageNumber) {
        this.$router.push({ query: { page: pageNumber } });
      }
    },

    changeDate() {
      console.debug('change date');
    },

    actionAddDate() {
      let { dates } = this.event;
      if (!dates) {
        this.$set(this.event, 'dates', []);
        dates = this.event.dates;
      }
      dates.push({
        new: true,
        uuid: Utils.uuid4(),
        start_time: new Date().toISOString(),
        end_time: new Date().toISOString(),
        lineup: [],
      });
    },

    removeDateById(eventDateId) {
      const { dates } = this;
      const itemIndex = dates
        .map((d) => d.uuid)
        .indexOf(eventDateId);

      console.debug(`removing item ${eventDateId} at index ${itemIndex}`);
      if (itemIndex >= 0) {
        dates.splice(itemIndex, 1);
      }
      this.dates = dates;
    },

    actionRemoveDate(eventDateId, isNew) {
      console.debug(`removing date ${eventDateId}, new: ${isNew}`);
      if (isNew) {
        this.removeDateById(eventDateId);
      } else {
        this.$store.dispatch('eventDates/deleteEventDate', eventDateId)
          .then((response) => {
            if (response.success) {
              this.removeDateById(eventDateId);
            }
          });
      }
    },

    getFieldError(fieldName) {
      return this.errors[fieldName];
    },

    setFieldError(fieldName, value) {
      this.$set(this.errors, fieldName, value);
    },

    uploadFileFromDrag(event) {
      let request = null;
      console.debug(`upload file from drag: ${event}`);
      const file = this.getFileFromDrag(event);
      if (file) {
        request = this.uploadImage(file);
      }
      return request;
    },

    onModalFileDialogSelect({ event, field, index }) {
      console.debug(`image list file upload: ${event}, ${field}, ${index}`);
      const file = this.getFileFromDialog(event);
      if (file) {
        const request = this.uploadImage(file, 'imageModalFileUpload');
        if (request) {
          request
            .then((response) => {
              if (response.success) {
                if (response.data) {
                  this.updateImageField(response.data, field, index);
                }
              }
            })
            .finally(() => {
              $(`#${this.modalId}`).modal('hide');
            });
        }
      }
    },

    uploadImage(file, loading) {
      console.debug(`file upload: ${file}`);

      const data = {
        loading,
        file,
        alt: file.name,
      };
      return this.$store.dispatch('images/uploadImage', data);
    },

    getFileFromDrag(event) {
      let file = null;
      const { files } = event.dataTransfer;
      if (files && files.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        file = files[0];
      }
      return file;
    },

    getFileFromDialog(event) {
      let file = null;
      const { files } = event.target;
      if (files && files.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        file = files[0];
      }
      return file;
    },

    removeMainEventImage() {
      this.mainEventImage = null;
    },

    onShowModal() {
      console.debug('image popup');
      this.imageListCurrentImage = {};
      this.$store.dispatch('images/getImages')
        .then((response) => {
          if (response.success) {
            this.imageList = response.data.objects;
          }
        });
    },

    buttonSelectModalImage() {
      $(`#${this.modalId}`).modal('hide');
      const image = this.imageListCurrentImage;
      if (image && image.image) {
        this.selectedModalImage = this.imageListCurrentImage;
        console.debug(`select image: ${this.selectedModalImage.alt}`);
        const { field, index } = this.activeImageFieldData;
        this.updateImageField(this.selectedModalImage, field, index);
        // force Vue to re-render
        this.$forceUpdate();
      }
      this.imageListBrowse = false;
    },

    updateImageField(data, field, index) {
      switch (field) {
        case 'event_image':
          this.mainEventImage = data;
          break;
        case 'images':
          this.$set(this.images, index, data);
          break;
        default:
      }
    },

    onImageDrop({ event, field, index }) {
      console.debug(`onImageDrop: ${event}, ${field}, ${index}`);
      const request = this.uploadFileFromDrag(event);
      if (request) {
        request.then((response) => {
          if (response.success) {
            console.debug(`response: ${response}`);
            this.updateImageField(response.data, field, index);
          }
        });
      }
    },

    modalBrowseImages() {
      this.imageListBrowse = true;
      this.onShowModal();
    },

    onImageEditClick(data) {
      console.debug('event image set');
      this.activeImageFieldData = data;
    },

    removeImagesItem(index) {
      const { images } = this;
      images.splice(index, 1);
      this.images = images;
    },

    getImageSrc(image) {
      return image ? image.image : '';
    },

    buttonCancelModalSelection() {
      this.imageListCurrentImage = {};
      $('#editImageModal').modal('hide');
    },

    eventDateClasses(eventDateIndex) {
      if (eventDateIndex === this.selectDatePosition) {
        return ['btn-primary'];
      }

      return ['btn-light', 'text-dark'];
    },

    getUserTimezone() {
      return Intl.DateTimeFormat().resolvedOptions().timeZone;
    },

    parseEventDate(dateString) {
      const datetime = this.getDatetimeFromValue(dateString);
      return this.getDisplayValue(datetime);
    },

    getDisplayValue(luxonDatetime) {
      const tz = this.getUserTimezone();
      return luxonDatetime.setZone(tz).toLocaleString(DateTime.DATETIME_MED);
    },

    getDatetimeFromValue(value) {
      let isoString = value;
      if (typeof value !== 'string') {
        isoString = value.toISOString();
      }
      return DateTime.fromISO(isoString);
    },

    getDateName(startTime, endTime) {
      const startDatetime = this.getDatetimeFromValue(startTime);
      const endDatetime = this.getDatetimeFromValue(endTime);
      return `${this.getDisplayValue(startDatetime)} to ${this.getDisplayValue(endDatetime)}`;
    },

    setTicketFree(ticket, value) {
      const position = this.tickets
        .map((t) => t.uuid).indexOf(ticket.uuid);
      if (position >= 0) {
        this.$set(this.tickets[position], 'free', value);
        this.$set(this.tickets[position], 'price', value ? '0.0' : '1.0');
      }
    },

    getAndValidateTitle() {
      const fieldName = 'title';
      const value = this.event.title;
      const errors = [];
      let cleanValue = null;
      let errorText = '';

      if (!value || !value.trim()) {
        errors.push('Invalid title');
      }
      if (errors.length > 0) {
        errorText = errors.join('. ');
      } else {
        cleanValue = value.trim();
      }

      this.setFieldError(fieldName, errorText);
      return {
        fieldName,
        cleanValue,
        hasError: !!errorText,
      };
    },

    getAndValidateImages() {
      const { images } = this;
      const cleanValue = images
        .filter((i) => !i.new)
        .map((i) => i.uuid);
      return {
        fieldName: 'images',
        cleanValue,
        hasError: false,
      };
    },

    getAndValidateVenue() {
      const fieldName = 'venue';
      const venue = this.selectedVenue;
      const hasError = !venue.uuid;
      const cleanValue = venue.uuid;
      const errorText = hasError ? 'Venue is required' : '';
      this.setFieldError(fieldName, errorText);
      return {
        fieldName,
        cleanValue,
        hasError,
      };
    },

    getAndValidateEventImage() {
      const fieldName = 'event_image';
      const image = this.mainEventImage;
      const hasError = !image.uuid;
      this.setFieldError(fieldName, hasError ? 'Event image is required' : '');
      const cleanValue = image.uuid;
      return {
        fieldName,
        cleanValue,
        hasError: false,
      };
    },

    getAndValidateDescription() {
      const fieldName = 'description';
      const value = this.event.description;
      const errors = [];
      let cleanValue = null;
      let errorText = '';

      if (!value || !value.trim()) {
        errors.push('Invalid description');
      }
      if (errors.length > 0) {
        errorText = errors.join('. ');
      } else {
        cleanValue = value.trim();
      }

      this.setFieldError(fieldName, errorText);
      return {
        fieldName,
        cleanValue,
        hasError: !!errorText,
      };
    },

    getAndValidateTags() {
      const tags = this.selectedTags;
      const cleanValue = tags
        .filter((i) => !i.new)
        .map((i) => i.uuid);
      return {
        fieldName: 'tags',
        cleanValue,
        hasError: false,
      };
    },

    getAndValidateHighlight() {},

    getAndValidateEssentials() {
      const fieldName = 'essentials';
      const value = this.event.essentials;
      const cleanValue = this.isExperienceView ? value && value.trim() : '';
      const errorText = '';

      return {
        fieldName,
        cleanValue,
        hasError: !!errorText,
      };
    },

    getAndValidateWhatsIncluded() {
      const fieldName = 'whats_included';
      const value = this.event.whats_included;
      const cleanValue = this.isExperienceView ? value && value.trim() : '';
      const errorText = '';

      return {
        fieldName,
        cleanValue,
        hasError: !!errorText,
      };
    },

    getAndValidateCancelPolicy() {
      const fieldName = 'cancel_policy';
      const value = this.event.cancel_policy;
      const cleanValue = this.isExperienceView ? value && value.trim() : '';
      const errorText = '';

      return {
        fieldName,
        cleanValue,
        hasError: !!errorText,
      };
    },

    getValidDatetimeString(string) {
      console.debug(`converting ${string} to iso}`);
      let value = null;
      if (string) {
        try {
          value = string.toISOString();
        } catch (TypeError) {
          value = string;
        }
      }
      return value;
    },

    getAndValidateDates() {
      const { dates } = this;
      const cleanValue = dates
        .map((i) => (
          {
            new: i.new,
            uuid: i.uuid,
            start_time: this.getValidDatetimeString(i.start_time),
            end_time: this.getValidDatetimeString(i.end_time),
            lineup: i.lineup
              .filter((l) => !l.new)
              .map((l) => l.uuid),
          }
        ));
      return {
        fieldName: 'dates',
        cleanValue,
        hasError: false,
      };
    },

    getAndValidateEventType() {
      const cleanValue = this.isExperienceView ? 'experience' : 'function';
      return {
        fieldName: 'event_type',
        cleanValue,
        hasError: false,
      };
    },

    getAndValidateTickets() {
      const { tickets } = this;
      const cleanValue = tickets
        .map((i) => (
          {
            new: i.new,
            uuid: i.uuid,
            start_time: this.getValidDatetimeString(i.start_time),
            end_time: this.getValidDatetimeString(i.end_time),
            label: i.label,
            description: i.description,
            event_date: i.event_date && i.event_date.uuid,
            price: i.price,
            price_currency: 'SEK', // i.price_currency,
            qty_available: i.qty_available,
            qty_balance: i.qty_balance,
            qty_order_max: i.qty_order_max,
            qty_order_min: i.qty_order_min,
          }
        ));
      return {
        fieldName: 'tickets',
        cleanValue,
        hasError: false,
      };
    },

    getValidatedEventData() {
      const dataFields = [
        this.getAndValidateTitle(),
        this.getAndValidateDescription(),
        this.getAndValidateVenue(),
        this.getAndValidateEventImage(),
        this.getAndValidateImages(),
        this.getAndValidateTags(),
        this.getAndValidateDates(),
        this.getAndValidateEventType(),
        this.getAndValidateTickets(),
        this.getAndValidateCancelPolicy(),
        this.getAndValidateEssentials(),
        this.getAndValidateWhatsIncluded(),
      ];
      const hasErrors = dataFields.some((field) => field.hasError);
      if (hasErrors) {
        return false;
      }

      const data = {};
      dataFields.forEach((field) => {
        data[field.fieldName] = field.cleanValue;
      });
      data.duration = this.event.duration;
      return data;
    },

    saveEventResponseData(data) {
      Object.keys(data)
        .forEach((key) => {
          this.$set(this.event, key, data[key]);
        });
    },

    buttonSaveChanges() {
      console.debug('saving changes');
      const data = this.getValidatedEventData();
      if (data) {
        const { eventId } = this;
        const create = !eventId;
        const { tickets } = data;
        delete data.tickets;
        if (create) {
          this.$store.dispatch('events/createEvent', data)
            .then((response) => {
              const { success, errors } = response;
              if (success) {
                console.debug('request successful');
                if (response.data) {
                  const responseData = response.data;
                  this.saveEventResponseData(responseData);

                  let nextRoute = '';
                  let successText = '';
                  if (this.isExperienceView) {
                    nextRoute = 'DashboardExperienceDetail';
                    successText = 'Experience created successfully';
                  } else {
                    nextRoute = 'DashboardEventDetail';
                    successText = 'Event created successfully';
                  }

                  const newTickets = tickets.map((ticket) => (
                    { ...ticket, event_id: responseData.uuid }));
                  this.saveTicketData(newTickets);

                  this.$router.push({
                    name: nextRoute,
                    params: {
                      eventId: responseData.uuid,
                    },
                    query: {
                      page: this.currentPageNumber,
                    },
                  });
                  notify({
                    type: 'success',
                    message: successText,
                  });
                }
              } else {
                this.processErrors(errors);
              }
            });
        } else {
          this.$store.dispatch(
            'events/updateEvent',
            {
              eventId,
              data,
            },
          )
            .then((response) => {
              const { success, errors } = response;
              if (success) {
                console.debug('request successfule');
                if (response.data) {
                  const responseData = response.data;
                  this.saveEventResponseData(responseData);
                }

                notify({
                  type: 'success',
                  message: 'Changes updated successfully',
                });
              } else {
                this.processErrors(errors);
              }
            });

          const newTickets = tickets.map((ticket) => (
            { ...ticket, event_id: eventId }));
          this.saveTicketData(newTickets);
        }
      } else {
        notify({
          type: 'error',
          message: 'Some values are not valid. Check your input.',
        });
      }
    },

    saveTicketData(tickets) {
      console.debug('saving ticket data');
      if (tickets && tickets.length > 0) {
        const requests = [];
        tickets.forEach((ticket) => {
          let request = null;
          if (ticket.new) {
            request = this.$store.dispatch('tickets/createTicket', ticket);
          } else {
            request = this.$store.dispatch(
              'tickets/updateTicket',
              {
                ticketId: ticket.uuid,
                data: ticket,
              },
            );
          }
          requests.push(request);
        });

        Promise.all(requests)
          .then((responses) => {
            const errorList = [];
            let hasErrors = false;
            responses.forEach((response, index) => {
              const { success, data, errors } = response;
              if (success) {
                this.$set(this.tickets, index, data);
                errorList.push({});
              } else {
                hasErrors = true;
                errorList.push(errors);
              }
            });
            if (hasErrors) {
              this.processErrors({ tickets: errorList });
            }
          });
      }
    },

    unpackErrors(errorValue) {
      let error = '';
      if (errorValue.constructor === Array) {
        error = errorValue.join('. ');
      } else {
        error = errorValue;
      }
      return error;
    },

    processErrors(errors) {
      if (!_.isEmpty(errors)) {
        Object.keys(errors).forEach((key) => {
          if (key === 'dates') {
            errors[key].forEach((item, index) => {
              Object.keys(item).forEach((field) => {
                const error = this.unpackErrors(item[field]);
                this.$set(this.dates[index], `${field}_error`, error);
              });
            });
          } else if (key === 'tickets') {
            errors[key].forEach((item, index) => {
              Object.keys(item).forEach((field) => {
                const error = this.unpackErrors(item[field]);
                this.$set(this.tickets[index], `${field}_error`, error);
              });
            });
          } else {
            const error = this.unpackErrors(errors[key]);
            this.setFieldError(key, error);
          }
        });
      }
    },

    actionDelete() {
      const { eventId } = this;
      if (eventId) {
        this.$store.dispatch('events/deleteEvent', eventId)
          .then((response) => {
            if (response.success) {
              const nextRoute = this.isExperienceView ? 'DashboardExperiences' : 'DashboardEvents';
              this.$router.push({
                name: nextRoute,
              });
              notify({
                type: 'success',
                message: 'Item deleted successfully',
              });
            } else {
              notify({
                type: 'error',
                message: response.message,
              });
            }
          });
      }
    },

    actionAddTicket() {
      const { eventId } = this;
      if (this.isValidEventId(eventId)) {
        const ticket = {
          uuid: Utils.uuid4(),
          new: true,
          free: false,
          label: '',
          start_time: null,
          end_time: null,
          description: '',
          event_date: null,
          price: '1.0',
          price_currency: 'SEK',
          qty_available: 1,
          qty_balance: 1,
          qty_order_min: 1,
          qty_order_max: 0,
          event_id: eventId,
        };
        const tickets = [...this.tickets];
        tickets.push(ticket);
        this.tickets = tickets;
      } else {
        notify({
          type: 'info',
          message: 'Save changes before adding tickets',
        });
      }
    },

    removeTicket(ticketId) {
      this.tickets = this.tickets.filter((t) => t.uuid !== ticketId);
    },

    actionDeleteTicket(ticket) {
      const ticketId = ticket.uuid;
      if (ticketId) {
        if (ticket.new) {
          this.removeTicket(ticketId);
        } else {
          this.$store.dispatch('tickets/deleteTicket', ticketId)
            .then((response) => {
              if (response.success) {
                this.removeTicket(ticketId);
              }
            });
        }
      }
    },

    getVenuePosition() {
      const { venue } = this.event;
      return !_.isEmpty(venue) ? { lat: venue.latitude, lng: venue.longitude } : this.mapsCenter;
    },

    getEventDuration(duration) {
      this.event.duration = duration;
      if (duration) {
        const humanizedDuration = humanizeDuration(duration);
        console.log('Current Humanized Time is:: ', humanizedDuration);
        this.humanReadableDuration = `Approx. ${humanizedDuration}`;
        return humanizedDuration;
      }
      this.humanReadableDuration = 'Approx. N/A';
      return '';
    },

  },
};
</script>

<style lang="scss">
$multiselect-tag-padding: 15px;

.event-detail {
  .vue-map {
    button {
      background-color: $gray-200 !important;
      color: $gray-800 !important;
    }
  }

  form {
    .page-number {
      font-size: 14px;
    }

    .page-description {
      font-size: 12px;
      color: $placeholder-color;
    }

    .next-page {
      font-size: 14px;
    }

    .multiselect__tag {
      background-color: $gray-200;
      color: black;
      border-radius: 22px;
      padding: $multiselect-tag-padding 24px $multiselect-tag-padding $multiselect-tag-padding;
    }

    .multiselect__tag-icon {
      // center vertically
      display: flex;
      justify-content: center;
      flex-direction: column;
    }

    .img-container {
      position: relative;

      img:hover + .btn-action,
      .btn-action:hover {
        display: inline-block !important;

        button, label {
          background-color: $gray-200 !important;
          color: $wham-sec-dark-color !important;
        }

        button:hover, label:hover {
          background-color: $gray-400 !important;
        }
      }

      div.btn-action {
        display: none !important;
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    .img-container:hover {
      img {
        opacity: 0.5;
      }
    }

    .modal {
      .btn-switch button {
        // border-color: blue !important;
        border-radius: 15px;
      }

      .btn-switch button:disabled {
        background-color: $gray-200 !important;
        color: black !important;
      }

      .modal-body {
        img.img-list-item {
          max-height: 9rem;
          width: 100%;
          height: 100%;
        }

        img.img-list-item:hover {
          cursor: pointer;
        }

        img.active {
          border: 5px solid $wham-primary-color;
          border-radius: 5px;
        }
      }
    }
  }

  .event-detail-nav {
    .nav-item {
      .nav-number {
        background-color: $wham-sec-dark-color;
        color: $wham-sec-light-color;
      }
    }

    .active {
      border-left: 3px solid $wham-primary-color;
    }

    .nav-item:hover {
      cursor: pointer;

      .nav-number {
        background-color: $wham-primary-color;
      }
      .nav-text {
        color: $wham-primary-color;
      }
    }

    @media (max-width: 994px) {
      // display: none;
    }
  }

  @media (max-width: 994px) {
    .details-container {
      padding-left: 10px !important;
      padding-right: 10px !important;
    }

    .form-row, .event-tickets {
      // border: none !important;
      padding: 0 !important;
      margin-left: 0 !important;
      margin-right: 0 !important;
      margin-bottom: 3 !important;
      margin-top: 3 !important;
    }
  }
}
</style>>
