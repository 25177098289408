<template>
  <v-date-picker
    :mode="mode"
    v-model="mDate"
    :update-on-input="false"
  >
    <template v-slot="{ inputValue, inputEvents }">
      <label class="" :for="id">
        {{ label }}
      </label>
      <div class="input-group mb-1 mr-sm-2">
        <template v-if="iconName">
          <div class="input-group-prepend">
            <div class="input-group-text">
              <span class="material-icons-outlined">
              {{ iconName }}
              </span>
            </div>
          </div>
        </template>
        <input
          class="form-control bg-white border px-2 py-1 rounded"
          :id="id"
          :value="inputValue"
          :class="{'is-invalid': error}"
          v-on="inputEvents"
        />
      </div>
      <small v-if="error"
        :id="`validationFeedback${id}`"
        class="text-danger ml-2"
      >
        {{ error }}
      </small>
    </template>
  </v-date-picker>
</template>

<script>
export default {
  props: {
    index: String,
    date: {
      type: [String, Date],
      required: false, // may be null
    },
    label: String,
    iconName: String,
    mode: String,
    error: {
      type: String,
      required: false,
      default: '',
    },
  },

  data() {
    return {
      id: `datePickerInput${this.index}`,
    };
  },

  computed: {
    mDate: {
      set(value) {
        this.$emit('date-change', value);
      },

      get() {
        return this.date;
      },
    },
  },
};
</script>

<style>

</style>
