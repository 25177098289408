<template>
<!-- eslint-disable max-len -->
  <div class="custom-text-input">
    <div class="border rounded py-2 px-3 section-input">
      <label class="px-2 text-secondary mb-0 font-14" :for="id">
        {{ label }}
        <span v-if="required" class="text-danger">*</span>
      </label>
      <template v-if="rows > 1">
        <textarea class="form-control form-control-lg border-0 font-16 px-2 font-weight-bold"
          :class="{'is-invalid': error}"
          :id="id"
          v-model="data"
        />
      </template>
      <template v-else>
        <input type="text" class="form-control form-control-lg border-0 font-16 px-2 font-weight-bold"
          :class="{'is-invalid': error}"
          :id="id"
          v-model="data"
        />
      </template>
    </div>
    <template v-if="error">
      <small :id="`validationFeedback${id}`" class="text-danger ml-2">
        {{ error }}
      </small>
    </template>
    <template v-else>
      <small v-if="helpText" class="ml-2">
        {{ helpText }}
      </small>
    </template>
</div>
</template>

<script>
export default {
  name: 'CustomTextInput',
  props: {
    id: String,
    label: String,
    default: [String, Number],
    required: Boolean,
    helpText: {
      required: false,
      type: String,
      default: '',
    },
    rows: {
      required: false,
      type: Number,
      default: 1,
    },
    error: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value);
      },

      get() {
        return this.default;
      },
    },
  },
};
</script>

<style lang="scss">
$input-border: 1px solid rgba(81, 203, 238, 1);
$box-shadow: 0 0 5px rgba(81, 203, 238, 1);
$outline: 0 none;

.custom-text-input {
  .section-input {
    input, textarea {
      box-shadow: none !important;
      outline: 0px !important;
      -webkit-appearance:none;
    }

    input:focus,
    textarea:focus {
      transition: all 0.2s ease-in-out;
      -webkit-transition: all 0.2s ease-in-out;
      -moz-transition: all 0.2s ease-in-out;
      -ms-transition: all 0.2s ease-in-out;
      -o-transition: all 0.2s ease-in-out;
      font-size: 105%;
    }
  }

  .section-input:active,
  .section-input:focus-within {
    box-shadow: $box-shadow;
    border: $input-border;
  }

  small {
    color: $gray-600;
  }
}
</style>
