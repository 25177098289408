<template>
  <label :for="id"
    class="border w-100 image-pan pb-4"
    :class="{'active': dragStatus.over}"
    v-on:dragover.prevent="handleDragOver"
    v-on:drop.prevent="handleDrop"
    v-on:dragleave.prevent="handleDragLeave"
    v-on:click="handleClick"
  >
    <slot name="file">
      <input type="file" :id="id"
        hidden
        @change="handleFileSelect" />
    </slot>

    <div class="row h-50">
      <div class="col d-flex align-items-end">
        <span class="material-icons-outlined">
        {{  iconName }}
        </span>
      </div>
    </div>
    <div class="row h-50">
      <div class="col text-center help-text">
        <span class="font-weight-bold">
          {{ itemText }}
        </span>
        <small class="form-text text-muted">
          {{ itemHelpText }}
        </small>
      </div>
    </div>
  </label>
</template>

<script>
export default {
  props: {
    id: String,
    text: String,
    helpText: String,
    data: Object, // { field: 'fielname', index: <index in array> }
    icon: String,
  },

  data() {
    return {
      dragStatus: {
        over: false,
        dropped: false,
      },
    };
  },

  computed: {
    itemText() {
      if (this.text === null) {
        return '';
      }
      return this.text || 'Drag & drop or click to add image.';
    },

    itemHelpText() {
      if (this.helpText === null) {
        return '';
      }
      return this.helpText || 'JPEG or PNG, no larger than 10MB.';
    },

    iconName() {
      return this.icon || 'image';
    },
  },

  methods: {
    handleDragOver() {
      this.$set(this.dragStatus, 'over', true);
    },

    handleDrop(event) {
      this.$set(this.dragStatus, 'over', false);
      this.$set(this.dragStatus, 'dropped', true);
      this.$emit('drop', { event, ...this.data });
    },

    handleDragLeave() {
      this.$set(this.dragStatus, 'over', false);
    },

    handleFileSelect(event) {
      this.$emit('file-upload', { event, ...this.data });
    },

    handleClick() {
      this.$emit('clicked', this.data);
    },
  },
};
</script>

<style lang="scss">
.image-pan {
  cursor: pointer;
  height: 150px;
  border: 2px solid black;
  padding-left: 20px;
  -webkit-box-shadow: 0px -1px 12px -2px $gray-400;
  box-shadow: 0px -1px 12px -2px $gray-400;

  .material-icons-outlined {
    font-size: 48px;
    color: $gray-400;
  }

  .help-text {
    font-size: 18px;
  }
}

.image-pan:active {
  box-shadow: 0 0 5px -1px rgba(0,0,0,0.6);
}

.image-pan.active {
  background-color: rgba(0, 195, 255, 0.842);
}
</style>
